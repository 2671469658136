<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const SOLUTIONS = getSolutions();
const route = useRoute();

const swiperOptions = {
  modules: [Autoplay],
  breakpoints: {
    300: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
  },
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  loop: true,
};

const getCurrentSolution = () => {
  return SOLUTIONS[route.path.replace(/\/+$/, "").split("/").pop()];
};

const currentSolution = ref(getCurrentSolution());

watchEffect(() => {
  currentSolution.value = getCurrentSolution();
});
</script>

<template>
  <section>
    <h2 class="text-center">
      <span class="heading-3 text-secondary">Các giải pháp </span>
      <span class="heading-3 text-primary">liên quan</span>
    </h2>

    <div class="mt-[40px]">
      <swiper v-bind="swiperOptions">
        <template v-for="(solution, key) in SOLUTIONS">
          <swiper-slide
            v-if="
              solution.relatedSolutionIndex &&
              (!currentSolution || (currentSolution?.type === solution.type && currentSolution?.path !== solution.path))
            "
            class="!h-[232px]"
            :key="key"
          >
            <nuxt-link
              :to="solution.href || `/giai-phap${solution.path}`"
              :target="solution.href ? '_blank' : ''"
              class="py-[80px] px-[65px] text-center flex-center text-white bg-no-repeat bg-cover h-full"
              :style="{ backgroundImage: `url('/images/related-solutions/${solution.relatedSolutionIndex}.png')` }"
              >{{ solution.title }}
            </nuxt-link>
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </section>
</template>
