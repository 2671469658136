export const getSolutions = () => {
  return {
    "digital-signage": {
      type: "offline",
      path: "/digital-signage",
      title: "Digital Signage",
      icon: "/images/icons/ic-solution-ds.svg",
      relatedSolutionIndex: 3,
      relatedSolutionTitle: "Quảng cáo màn hình số thông minh (Digital Signage)",
    },
    "self-service-kiosk": {
      type: "offline",
      path: "/self-service-kiosk",
      title: "Self-service Kiosk",
      icon: "/images/icons/ic-solution-ssk.svg",
      relatedSolutionIndex: 5,
      relatedSolutionTitle: "Kiosk tự phục vụ (Self-service Kiosk)",
    },
    "he-thong-xep-hang-tu-dong": {
      type: "offline",
      path: "/he-thong-xep-hang-tu-dong",
      title: "Đặt lịch xếp hàng",
      icon: "/images/icons/ic-solution-sq.svg",
      relatedSolutionIndex: 1,
      relatedSolutionTitle: "Đặt lịch & Xếp hàng thông minh",
    },
    "vms-camera": {
      type: "offline",
      path: "/vms-camera",
      title: "VMS - AI Camera",
      icon: "/images/icons/ic-solution-vms-camera.svg",
      relatedSolutionIndex: 2,
      relatedSolutionTitle: "Nhận diện & Phân tích hành vi khách hàng",
    },
    "so-hoa-tai-lieu": {
      type: "offline",
      path: "/so-hoa-tai-lieu",
      title: "Số hóa tài liệu",
      icon: "/images/icons/ic-solution-shtl.svg",
      relatedSolutionIndex: 9,
      relatedSolutionTitle: "Số hóa tài liệu",
    },
    cdp: {
      type: "online",
      path: "/cdp",
      title: "CDP - Nền tảng phân tích dữ liệu khách hàng",
      icon: "/images/icons/ic-solution-cdp.svg",
      relatedSolutionIndex: 7,
      relatedSolutionTitle: "Nền tảng Phân tích dữ liệu khách hàng (CDP)",
    },
    "quan-ly-tai-san": {
      type: "online",
      path: "/quan-ly-tai-san",
      title: "Quản lý tài sản",
      icon: "/images/icons/ic-solution-qlts.svg",
      relatedSolutionIndex: 10,
      relatedSolutionTitle: "Quản lý tài sản",
    },
    "e-form": {
      type: "online",
      path: "/e-form",
      title: "Biểu mẫu điện từ E-Form",
      icon: "/images/icons/ic-solution-eform.svg",
      relatedSolutionIndex: 4,
      relatedSolutionTitle: "Biểu mẫu điện tử E-Form",
    },
    cx: {
      type: "online",
      path: "/cx",
      title: "Trải nghiệm khách hàng đa kênh",
      icon: "/images/icons/ic-solution-cx.svg",
      relatedSolutionIndex: 8,
      relatedSolutionTitle: "Nền tảng trải nghiệm khách hàng đa kênh",
    },
    "phan-hoi-khach-hang": {
      type: "online",
      path: "/phan-hoi-khach-hang",
      title: "Khảo sát và Phản hồi đa kênh (VOC)",
      icon: "/images/icons/ic-solution-cx.svg",
      relatedSolutionIndex: 11,
      relatedSolutionTitle: "Khảo sát và Phản hồi đa kênh (VOC)",
    },
    qrx: {
      type: "online",
      href: "https://qrx.com.vn/",
      title: "QRX - Nền tảng kinh doanh D2C",
      icon: "/images/icons/ic-solution-qr.svg",
      relatedSolutionIndex: 6,
      relatedSolutionTitle: "QR Code",
    },
  };
};
